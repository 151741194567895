/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {getScrollTriggers, setScrollTrigger} from "utils/scrolltriggers";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {ResourcePanel, Pin, InfoNugget, InfoItem, Headline, CareInstructions, CareItem} = _components;
  if (!CareInstructions) _missingMdxReference("CareInstructions", true);
  if (!CareItem) _missingMdxReference("CareItem", true);
  if (!Headline) _missingMdxReference("Headline", true);
  if (!InfoItem) _missingMdxReference("InfoItem", true);
  if (!InfoNugget) _missingMdxReference("InfoNugget", true);
  if (!Pin) _missingMdxReference("Pin", true);
  if (!ResourcePanel) _missingMdxReference("ResourcePanel", true);
  return React.createElement(React.Fragment, null, React.createElement(ResourcePanel, {
    id: "figure01",
    image: "figure01",
    imageAlt: "lorem ipsum",
    roundTopCorners: true,
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure01",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Skin bleaching (or lightening)"), ", has a long, deeply rooted history that extends beyond the act of using beauty products to lighten one's skin tone.")), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Skin lightening products have clinical uses that may be helpful in managing specific pigmentary disorders, such as"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, React.createElement("div", {
    id: "melasma"
  }), "Melasma"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "post inflammatory hyperpigmentation"), "\n"), "\n"), React.createElement(_components.p, null, "Dermatologists frequently use prescription products containing ingredients such as"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "hydroquinone"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "azeleic acid"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, "kojic acid"), "\n"), "\n"), React.createElement(_components.p, null, "These products should ", React.createElement(_components.strong, null, "not be used with the goal of lightening one's natural skintone"), ".")), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "Melasma"), React.createElement(_components.p, null, "Melasma is a pigmentary disorder in which irregular brown or blue gray patches form on the skin, most often the face."), React.createElement(_components.p, null, "It is most common in women with brown skin or skin that tans easily. It is also common during pregnancy.")), React.createElement(Pin, {
    id: "figure01",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure02",
    image: "figure02",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers,
    slug: props.data.mdx.frontmatter.slug
  }, React.createElement(Pin, {
    id: "figure02",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "Post Inflammatory Hyperpigmentation"), React.createElement(_components.p, null, "Post inflammatory hyperpigmentation develops when the skin produces extra pigment after injury, irritation, or disease."), React.createElement(_components.p, null, "It is more common in melanin-rich skin and tends to be prominent and longer-lasting.")), React.createElement(Pin, {
    id: "figure02",
    type: "end",
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure03",
    image: "figure03",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure03",
    type: "start",
    height: 400,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "Misuse of Skin Lightening Products"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Exogenous ochronosis"), " is a condition that can result from misuse of topical hydroquinone, and is very difficult to treat."), React.createElement(_components.p, null, "It is characterized by blueish black patches on the skin."), React.createElement(_components.p, null, "These typically affect areas where hydroquinone is applied.")), React.createElement(Pin, {
    id: "figure03",
    type: "end",
    height: 400,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(CareInstructions, null, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Dermatologists frequently use prescription products to help treat pigmentary disorders such as melasma and post inflammatory hyperpigmentation. These include ingredients such as hydroquinone, azelaic acid and kojic acid."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Bleaching products should be used under the care of a professional due to the adverse effects associated with over the counter products containing mercury or IV glutathione."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "These products should ", React.createElement(_components.strong, null, "not be used"), " with the goal of lightening one's natural skintone"), "\n"), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
